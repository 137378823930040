export enum StatusEnum {
  posted = 'posted',
  inProgress = 'inProgress',
  error = 'error',
}

export type ScraperDataType = {
  accessToken: string;
  date: string;
  url: string;
  status: StatusEnum | null;
};

export type SelectDataType = {
  accessToken: string;
  name: string;
};

export type ApiSelectDataType = {
  access_token: string;
  name: string;
  address: string;
};

export type ScraperEntitiesType = Record<number, ScraperDataType>;

export enum MetaEnum {
  start = 'start',
  loading = 'loading',
  loaded = 'loaded',
}

export enum LoggedInEnum {
  start = 'start',
  loggedIn = 'loggedIn',
  error = 'error',
}
