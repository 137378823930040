import * as React from 'react';

import s from './Footer.module.scss';

const Footer: React.FC = () => {
  return (
    <div className={s.wrapper}>
      <div className={s.column}>
        <div className={s.title}>About us</div>
        <div className={s.text}>
          Yla.lt is dedicated to boosting your business success. Contact us for
          more information via email.
        </div>
      </div>
      <div className={s.column}>
        <div className={s.title}>Contact us</div>
        <div className={s.text}>
          Phone:
          <a href="tel:+37064367020"> (8643) 67 020</a>
        </div>
        <div className={s.text}>
          Email:
          <a href="mailto:offers@yla.eu"> admin@yla-lt.lt</a>
        </div>
        <div className={s.text}>
          Address:
          <a href=""> Girulių g. 10, Vilnius</a>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Footer);
