import * as React from 'react';

import { Map, Navigation, Footer } from '../../components';

import {
  WorkDoneSection,
  ServicesSection,
  InformationSection,
} from './sections';

const Main: React.FC = () => {
  const fetchScrapedData = async () => {
    const response = await fetch('/scrape');
    const data = await response.json();
    console.log(data);
  };

  React.useEffect(() => {
    fetchScrapedData();
  }, []);

  return (
    <>
      <Navigation />
      <WorkDoneSection />
      <ServicesSection />
      <InformationSection />
      <Map />
      <Footer />
    </>
  );
};

export default React.memo(Main);
