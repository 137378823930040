import cn from 'classnames';
import * as React from 'react';

import s from './Button.module.scss';

export enum ButtonStyleEnum {
  primary = 'primary',
  secondary = 'secondary',
}

type Props = {
  style: ButtonStyleEnum;
  children: React.ReactNode;
  onClick?: VoidFunction;
  className?: string;
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const Button: React.FC<Props> = ({ style, children, onClick, className }) => {
  return (
    <button onClick={onClick} className={cn(className, s.button, s[style])}>
      {children}
    </button>
  );
};

export default Button;
