import * as React from 'react';

import { ReactComponent as Email } from '../../../../styles/img/email.svg';

import s from './InformationSection.module.scss';

const InformationSection: React.FC = () => {
  return (
    <section className={s.wrapper}>
      <Email className={s.icon} />
      <div className={s.text}>
        Yla.lt is dedicated to boosting your business success. Contact us for
        more information via email.
      </div>
    </section>
  );
};

export default InformationSection;
