import * as React from 'react';

import s from './WorkDoneSection.module.scss';

const WorkDoneSection: React.FC = () => {
  return (
    <section className={s.wrapper}>
      <div className={s.logo} />
      <div className={s.title}>We make it happen!</div>
      <div className={s.description}>
        Yla.lt is a digital marketing and consulting firm that connects brands
        to their desired audience through high-performance solutions and
        expertise. Our versatile approach, combined with our embrace of evolving
        technology and innovative thinking, allows us to surpass client
        expectations and find the ideal solutions.
      </div>
    </section>
  );
};

export default WorkDoneSection;
