import axios from 'axios';
import { action, makeObservable, observable, runInAction, toJS } from 'mobx';

import {
  ApiSelectDataType,
  LoggedInEnum,
  MetaEnum,
  ScraperEntitiesType,
  SelectDataType,
  StatusEnum,
} from './types';

export class ScraperStore {
  scraperData: ScraperEntitiesType = {
    0: { accessToken: '', date: '', url: '', status: null },
  };
  selectData: SelectDataType[] = [];
  currentIndex = 0;
  scraperMeta: MetaEnum = MetaEnum.start;
  loggedIn: LoggedInEnum | null;

  constructor() {
    this.connectDb();
    this.loggedIn =
      (window.localStorage.getItem('login') as LoggedInEnum | null) ===
      LoggedInEnum.error
        ? LoggedInEnum.start
        : (window.localStorage.getItem('login') as LoggedInEnum | null);

    makeObservable<ScraperStore>(this, {
      selectData: observable,
      scraperData: observable,
      currentIndex: observable,
      scraperMeta: observable,
      loggedIn: observable,

      addField: action,
      setToken: action,
      setDate: action,
      setUrl: action,
      connectDb: action,
      login: action,
    });
  }

  connectDb = async () => {
    axios
      .get('https://www.autoposteris.shop/read')
      .then((response) =>
        runInAction(() => {
          this.selectData = response.data.map(ScraperStore.normalizeSelectData);
        })
      )
      .catch((error) => console.error(error));
  };

  login = async (login: string) => {
    this.scraperMeta = MetaEnum.loading;
    await axios
      .get(`https://www.autoposteris.shop/login?login=${login}`)
      .then((response) =>
        runInAction(() => {
          this.loggedIn = response.data;
          this.scraperMeta = MetaEnum.loaded;
          window.localStorage.setItem('login', response.data);
          console.log(response.data);
        })
      )
      .catch((error) => {
        console.error(error);
        this.scraperMeta = MetaEnum.loaded;
      });
  };

  setToken = (id: number, value: string) => {
    this.scraperData[id].accessToken = value;
  };

  setDate = (id: number, value: string) => {
    this.scraperData[id].date = value;
  };

  setUrl = (id: number, value: string) => {
    this.scraperData[id].url = value;
  };

  addField = () => {
    const newIndex = Object.keys(this.scraperData).length;

    this.scraperData[newIndex] = {
      accessToken: '',
      date: '',
      url: '',
      status: null,
    };
  };

  start = async () => {
    this.scraperMeta = MetaEnum.loading;
    try {
      while (this.currentIndex < Object.keys(this.scraperData).length) {
        const { url, accessToken, date } = this.scraperData[this.currentIndex];
        const response = await axios.get(
          `https://www.autoposteris.shop/scrape?url=${url}&token=${accessToken}&date=${date}`
        );
        if (response) {
          runInAction(() => {
            this.scraperData[this.currentIndex].status = StatusEnum.posted;
            this.currentIndex++;
          });
        }
      }
    } catch (error) {
      runInAction(() => {
        this.scraperMeta = MetaEnum.loaded;
        this.scraperData[this.currentIndex].status = StatusEnum.error;
      });
    }
    this.scraperMeta = MetaEnum.loaded;
  };

  static normalizeSelectData = (
    apiData: ApiSelectDataType
  ): SelectDataType => ({
    accessToken: apiData.access_token,
    name: apiData.name,
  });
}

const scraperStore = new ScraperStore();

export const store = { scraperStore };
