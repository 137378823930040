import * as React from 'react';

import s from './BlueSection.module.scss';

const BlueSection: React.FC = () => {
  return (
    <div className={s.wrapper}>
      <div className={s.icon} />
      <div className={s.title}>About us and our work</div>
      <div className={s.text}>
        Yla.lt is a digital marketing and consulting firm that connects brands
        to their desired audience. Our field expertise and talented team aim to
        surpass client expectations and drive business growth beyond
        imagination. In today&apos;s digital age, every online interaction is an
        opportunity to reach customers, and we are here to help. Don&apos;t let
        the future pass you by &mdash; reach out to us via email now!
      </div>
    </div>
  );
};

export default BlueSection;
