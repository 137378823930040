import * as React from 'react';

export const SERVICES = [
  {
    title: 'Data Management and Analysis',
    services: [
      'Data collection and analysis',
      'Statistical modeling and predictive analytics',
    ],
  },
  {
    title: 'Customer and Market Insights',
    services: [
      'Customer segmentation and profiling',
      'Market research and competitive analysis',
    ],
  },
  {
    title: 'Marketing Strategy and Performance',
    services: ['Marketing mix modeling', 'Attribution modeling'],
  },
  {
    title: 'Digital Analytics and Channels',
    services: [
      'Digital analytics (web, mobile, social media)',
      'Customer lifetime value (CLV) analysis',
    ],
  },
  {
    title: 'Marketing Technology and Automation',
    services: ['Marketing automation and CRM integration'],
  },
  {
    title: 'Data Visualization and Reporting',
    services: [
      'Data visualization and dashboard development',
      'Business performance metrics tracking and reporting',
    ],
  },
  {
    title: 'Testing, Optimization, and Forecasting',
    services: [
      'A/B testing and experimentation',
      'Campaign measurement and optimization',
      'Sales forecasting and trend analysis',
    ],
  },
];
