import * as React from 'react';

import s from './EasyToFindSection.module.scss';

const EasyToFindSection: React.FC = () => {
  return (
    <div className={s.wrapper}>
      <div className={s.overlay} />
      <div className={s.icon} />
      <div className={s.title}>Easy to Find</div>
    </div>
  );
};

export default EasyToFindSection;
