import { format } from 'date-fns-tz';
import { observer } from 'mobx-react';
import * as React from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import { ReactComponent as ErrorIcon } from '../../../../styles/img/error-icon.svg';
import { ReactComponent as PostedIcon } from '../../../../styles/img/posted-icon.svg';
import { StatusEnum, useStore } from '../../store';

import s from './DataField.module.scss';

type Props = {
  id: number;
};

const DataField: React.FC<Props> = ({ id }) => {
  const store = useStore();
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);

  const handleTokenChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    store.setToken(id, event.target.value);
  };

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
    if (date) {
      // eslint-disable-next-line
      const formattedDate = format(date, "yyyy-MM-dd'T'HH:mm:ssxxx");
      store.setDate(id, formattedDate);
    }
  };

  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    store.setUrl(id, event.target.value);
  };

  return (
    <div className={s.wrapper}>
      <select
        value={store.scraperData[id].accessToken}
        onChange={handleTokenChange}
        className={s.token}
      >
        <option value="">Choose access token</option>
        {store.selectData.map((data, index) => (
          <option value={data.accessToken} key={index}>
            {data.name}
          </option>
        ))}
      </select>
      <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        showTimeSelect
        timeFormat="HH:mm:ss"
        timeIntervals={10}
        dateFormat="dd/MM/yyyy HH:mm:ss"
        placeholderText="Choose date"
      />
      <input
        value={store.scraperData[id].url}
        onChange={handleUrlChange}
        placeholder="Enter url"
        className={s.url}
      />
      <div className={s.status}>
        {store.scraperData[id].status === StatusEnum.posted && (
          <PostedIcon className={s.icon} />
        )}
        {store.scraperData[id].status === StatusEnum.error && (
          <ErrorIcon className={s.icon} />
        )}
      </div>
    </div>
  );
};

export default observer(DataField);
