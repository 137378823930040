import * as React from 'react';

import s from './ServicesSection.module.scss';
import { SERVICES } from './config';

const ServicesSection: React.FC = () => {
  return (
    <section className={s.wrapper}>
      <div className={s.columns}>
        <div className={s.image} />
        <div className={s.services}>
          {SERVICES.map((service, index) => (
            <div className={s.services__item} key={index}>
              <div className={s.title}>{service.title}:</div>
              <ul className={s.list}>
                {service.services.map((listItem, index) => (
                  <li key={index} className={s.list__item}>
                    {listItem}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
