import cn from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';

import s from './Scraper.module.scss';
import { Button, ButtonStyleEnum, DataField, Login } from './components';
import { LoggedInEnum, MetaEnum, useStore } from './store';

const Scraper: React.FC = () => {
  const store = useStore();

  const onAddClick = () => {
    store.addField();
  };

  const onStartClick = () => {
    store.start();
  };

  return (
    <div
      className={cn(
        s.wrapper,
        store.scraperMeta === MetaEnum.loading && s.wrapper_loading
      )}
    >
      {store.scraperMeta === MetaEnum.loading && <span className={s.spinner} />}
      {store.loggedIn === LoggedInEnum.loggedIn ? (
        <div className={s.scrapperWrapper}>
          <h1 className={s.title}>AUTOPOSTERIS</h1>
          {Object.keys(store.scraperData).map((key) => (
            <DataField key={key} id={parseInt(key)} />
          ))}
          <Button onClick={onStartClick} style={ButtonStyleEnum.primary}>
            START
          </Button>
          <Button onClick={onAddClick} style={ButtonStyleEnum.secondary}>
            ADD
          </Button>
        </div>
      ) : (
        <Login />
      )}
    </div>
  );
};

export default observer(Scraper);
