import * as React from 'react';
import { Link } from 'react-router-dom';

import s from './Navigation.module.scss';

const Navigation: React.FC = () => {
  return (
    <div className={s.wrapper}>
      <div className={s.navbar}>
        <div className={s.logo} />
        <div className={s.menu_list}>
          <div className={s.menu_list__item}>
            <Link to={'/'}>HOME</Link>
          </div>
          <div className={s.menu_list__item}>
            <Link to={'/about'}>ABOUT</Link>
          </div>
          <div className={s.menu_list__item}>
            <Link to={'/contacts'}>CONTACTS</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Navigation);
