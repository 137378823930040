import * as React from 'react';

import { Navigation } from '../../components';

import { AboutWorkDoneSection, BlueSection, BottomSection } from './sections';

const About: React.FC = () => {
  return (
    <>
      <Navigation />
      <AboutWorkDoneSection />
      <BlueSection />
      <BottomSection />
    </>
  );
};

export default React.memo(About);
