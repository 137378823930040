import * as React from 'react';

import { Footer, Map, Navigation } from '../../components';

import { EasyToFindSection } from './sections';

const Contacts: React.FC = () => {
  return (
    <>
      <Navigation />
      <EasyToFindSection />
      <Map />
      <Footer />
    </>
  );
};

export default React.memo(Contacts);
