import cn from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';

import { LoggedInEnum, useStore } from '../../store';
import { Button, ButtonStyleEnum } from '../Button';

import s from './Login.module.scss';

const Login: React.FC = () => {
  const store = useStore();
  const [inputValue, setInputValue] = React.useState('');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    store.login(inputValue);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  return (
    <form onSubmit={handleSubmit} className={s.wrapper}>
      <div className={s.text}>Login:</div>
      <input
        type="text"
        value={inputValue}
        onChange={handleChange}
        className={s.input}
      />
      {store.loggedIn === LoggedInEnum.error && (
        <div className={s.error}>Invalid username. Please try again.</div>
      )}
      <Button
        type="submit"
        style={ButtonStyleEnum.primary}
        className={s.button}
      >
        SUBMIT
      </Button>
    </form>
  );
};

export default observer(Login);
