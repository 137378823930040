import * as React from 'react';

import s from './Map.module.scss';

const Map: React.FC = () => {
  return (
    <div className={s.wrapper}>
      <div className={s.map} />
    </div>
  );
};

export default React.memo(Map);
