import { MobXProviderContext } from 'mobx-react';
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { About, Contacts, Main } from './pages';
import { Scraper } from './pages/Scraper';
import { store } from './pages/Scraper/store';

function App() {
  return (
    <MobXProviderContext.Provider value={store}>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/about" element={<About />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/scraper" element={<Scraper />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </MobXProviderContext.Provider>
  );
}

export default App;
